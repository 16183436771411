<template>
  <tr>
    <td>
      {{alert.dateToLocal()}}
    </td>
    <td>
      {{alert.data.lvl}}
    </td>
    <td>
      {{alert.group_key.insertion_order_id}}
    </td>
    <td>
      {{alert.data.spending_first_plug_day}}
    </td>
    <td>
      {{alert.data.spending_day_1}}
    </td>
  </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'PerfFirstNoDeliveryDetailed',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
  computed: {

  },
  watch: {
  }
}
</script>

<style scoped>

</style>
